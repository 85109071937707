import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VSheet,{staticClass:"header-backup"}),_c(VAppBar,{staticClass:"px-sm text-left shadow-sm ma-4 rounded-lg",attrs:{"color":_vm.$vuetify.theme.dark ? 'dark' : _vm.getThemeMode.appBarColor,"dark":_vm.getThemeMode.appBarColor != 'white' ? true : false,"app":"","flat":"","height":"75"}},[_c(VAppBarNavIcon,{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple",value:({ class: 'primary--text' }),expression:"{ class: 'primary--text' }"}],on:{"click":_vm.toggleVerticalSidebarDrawer}}),_c('vue-navigation-bar',{staticClass:"mt-0",attrs:{"options":_vm.navbarOptions}}),_c(VProgressLinear,{attrs:{"active":_vm.getThemeMode.isLoading,"indeterminate":_vm.getThemeMode.isLoading,"absolute":"","bottom":"","color":"primary"}}),_c(VSpacer),_c(VChip,{staticClass:"transparent py-5",attrs:{"pill":""},on:{"click":function($event){_vm.userDrawer = !_vm.userDrawer}}},[_vm._v(" Hi, "+_vm._s(_vm.user.firstName)+" "),_c(VAvatar,{staticClass:"ml-2"},[_c(VImg,{attrs:{"src":require("@/assets/images/svg/login.svg")}})],1)],1)],1),_c(VNavigationDrawer,{attrs:{"fixed":"","right":"","height":"100%","temporary":"","floating":"","width":"350"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"my-4 mx-4"},[_c('base-hover-button',{attrs:{"text":"Logout","block":"","bg-color":"primary lighten-5 primary--text","icon-name":"mdi-logout"},nativeOn:{"click":function($event){return _vm.logoutUser.apply(null, arguments)}}})],1)]},proxy:true}]),model:{value:(_vm.userDrawer),callback:function ($$v) {_vm.userDrawer=$$v},expression:"userDrawer"}},[_c('user-drawer',{scopedSlots:_vm._u([{key:"userDrawerCloseButton",fn:function(){return [_c(VBtn,{attrs:{"icon":"","color":""},on:{"click":function($event){$event.stopPropagation();_vm.userDrawer = !_vm.userDrawer}}},[_c(VIcon,[_vm._v("mdi-close")])],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }